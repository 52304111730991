<template lang="html">
  <section class="support support-getting-started about-us">
    <section class="slider-full">
      <div class="slide ">
        <div class="d-flex jcsb container-slide container aic">
          <div class="div-text ">
            <Waypoint>
              <h1 class="title-big -whiteC">
                {{ $t('getting_started.intro') }}
              </h1>
            </Waypoint>
            <Waypoint>
              <p class="-whiteC">
                {{ $t('getting_started.texto1') }}
                <br>
                {{ $t('getting_started.texto1_1') }}
                <router-link :to="{ 'name': 'support-how' }" class="-blueC">
                  {{ $t('getting_started.enlace') }}
                </router-link> 
                {{ $t('getting_started.texto1_2') }}
              </p>
            </Waypoint>
          </div>
          <div class="div-image">
            <Vue3Lottie :animationData="headSupportIntern" height="auto" width="100%" :loop="1" />
          </div>
        </div>
      </div>
    </section>
    <section class="content-info container" v-if="store.starting.itemSelected">
      <p class="title-medium -blueC">{{ store.starting.itemSelected.name }}</p>
      <EditorJs :object="store.starting.itemSelected.body" />
    </section>
    <section class="other-support-container">
      <div class="container">
        <h2 class="title-medium">{{ $t('support.other') }}</h2>
        <div class="container-link">
          <router-link :to="{ 'name': 'support-how' }" class="item">
            <img src="/img/icons/how-videos-black.svg" alt=" Getting Started">
            <p class="title-semi-medium -blueDarkC">
              {{ $t('support.how_to_videos') }}
            </p>
            <p class="description">
              {{ $t('support.how_to_videos_text') }}

            </p>
          </router-link>
          <router-link :to="{ 'name': 'support-release' }" class="item">
            <img src="/img/icons/relased-notes-black.svg" alt="How to Videos">
            <p class="title-semi-medium -blueDarkC">
              {{ $t('support.release_notes') }}

            </p>
            <p class="description">
              {{ $t('support.release_notes_text') }}
            </p>
          </router-link>
        </div>
      </div>
    </section>
    <section class="banner-footer">
      <div class="container">
        <p class="title-medium -blueDarkC">{{ $t('support.intro5') }}</p>
        <router-link :to="{ name: 'contact' }" class="btn-blue">
          {{ $t('support.button') }}
        </router-link>
      </div>
    </section>
  </section>

</template>

<script lang="js">
import moment from 'moment'
import { Vue3Lottie } from 'vue3-lottie'
import 'vue3-lottie/dist/style.css'
import headSupportIntern from '@/lotties/head-support-intern.json'
import { contentStore } from '@/stores/contents'
import EditorJs from "@/components/editorJs.vue"
const store = contentStore();
export default {
  name: 'support-getting-started',
  props: [],
  components: {
    EditorJs,
    Vue3Lottie
  },
  setup() {
    return {
      store,
      getStarting: store.getStarting,
      getStartingPage: store.getStartingPage
    }
  },
  mounted() {
    store.loadStarting({
      page: this.page,
    })
  },
  data() {
    return {
      headSupportIntern
    }
  },
  methods: {
    currentDateTime(date) {
      return moment(date).locale('en').format('LL')
    },
  },
  computed: {
    page() {
      return store.getStartingPage
    },
  },
  watch:{
    "store.lang": function(newLang){
      store.loadStarting({
        page: this.page,
      })
    }
  }
}


</script>

<style scoped lang="scss">
  .body-app {
  padding-top: 87px;
}
.support-getting-started {}
@media screen and (max-width:600px){
  .support.about-us .slider-full .slide {
        background: #1E2F3F;
        position: relative;
        height: 520px;
    }
    .title-medium{
      text-align: center;
    }
    .destacado{
      text-align: center;
    }
}
</style>